
import { Component, Mixins } from 'vue-property-decorator';
import {
  PageTitle,
  TextField,
  Stepper,
  StepperStep,
  SuccessDialog,
} from '@/components';
import { attractions_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import Data from '../components/attraction-form-steps/BaseAttractionDataForm.vue';
import Fields from '../components/attraction-form-steps/FieldsAttractionForm.vue';
import { Attractions } from '../types';

const { state, actions } = attractions_store;

@Component({
  components: {
    PageTitle,
    TextField,
    Data,
    Fields,
    Stepper,
    StepperStep,
    SuccessDialog,
  },
})
export default class AddNewAttraction extends Mixins(ErrorHandlereMixin) {
  step = 1;

  attraction = { ...state.base_attraction };

  success_dialog = false;

  on_create_error = false;

  get fields() {
    return state.base_attraction_fields;
  }

  submit_first_step(val: Attractions.Attraction) {
    this.attraction = val;
    this.step += 1;
  }

  to_attractions() {
    this.$router.push({
      name: Attractions.Pages.ATTRACTIONS,
    });
  }

  async submit_last_step(val: Attractions.TimeslotTableCols[]) {
    try {
      this.attraction.fields = val;
      await actions.create_attraction(this.attraction);

      this.success_dialog = true;
    } catch (error) {
      this.handleServerError(error);
      this.on_create_error = true;
    }
  }
}
